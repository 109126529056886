<template>
  <section class="ls-page">
    <div class="ps-section__header ls-page-header-no-bg" style="background-image: url(../img/cover_buy_voucher.jpg)">
    </div>
    <div class="ps-section__content ls-page-content mb-5">
      <h3 class="container ls-page-header-subtitle">
        Instead of sending pre-selected goods or service, buy your Zim bases
        next of kin a Voucher for any denomination upto $500 that can be
        redeemed for goods and services of their choice at a selected or any of
        the Pahakuma merchants available.
      </h3>
      <div v-if="!auth.isAuthenticated" class="ls-center row">
        <div class="col">
          <button @click="recharge()" class="m-3 ps-btn btn-sm">
            Voucher History</button>
        </div>
        <div class="col">
          <button class="m-3 ps-btn btn-sm">
            ReSend Voucher</button>
        </div>
        <div class="col">
          <button class="m-3 ps-btn btn-sm">
            Voucher Balances</button>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseRunner",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    // this.getRunnersDetails();
  },
  data() {
    return {
      runnerCategories: [],
      myRequests: [],
      pendingRequests: [],
      completedRequests: [],
      inProgressRequests: [],
    };
  },
  methods: {
    // getRunnersDetails() {
    //   axios.get("my-vouchers").then((response) => {
    //     console.log("Get Vouchers : " + JSON.stringify(response.data.data));
    //     this.myRequests = response.data.data;
    //     for (var i = 0; i < this.myRequests.length; i++) {
    //       switch (this.myRequests[i].status) {
    //         case "1":
    //           this.pendingRequests.push(this.myRequests[i]);
    //           break;
    //         case "2":
    //           this.completedRequests.push(this.myRequests[i]);
    //           break;
    //         case "3":
    //           this.inProgressRequests.push(this.myRequests[i]);
    //           break;
    //       }
    //     }
    //     // this.pendingRequests = response.data.data.length;
    //   });
    // },
  },
};
</script>

<style scoped></style>
